import { plug_in_light_icon, plug_in_dark_icon } from '@common/assets/images';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

export interface PlugInButtonProps {
  t: (arg0: string) => string;
  history: any;
}

class PlugInButton extends Component<PlugInButtonProps> {
  constructor(props: PlugInButtonProps) {
    super(props);
  }
  render(): React.ReactNode {
    const { t } = this.props;
    return (
      <div className="plugInContainer" data-testid="plugInContainer">
        <button className="button--secondary" data-testid="plugin-btn" onClick={() => {}}>
          <img
            src={plug_in_light_icon}
            alt="Plug Icon"
            className="plug-icon light-icon"
            data-testid="plug-icon-light"
          />
          <img
            src={plug_in_dark_icon}
            alt="Plug Icon"
            className="plug-icon dark-icon"
            data-testid="plug-icon-dark"
          />
          <span data-testid="plugin-text">{t('plug_in')}</span>
        </button>
      </div>
    );
  }
}
export default withTranslation('translations')(PlugInButton as any);
export { PlugInButton as PlugInButton }; //(only for testing purpose).
