import {
  available_network,
  regular_charging_icon,
  fast_charging_icon,
  ultra_fast_charging_icon,
  ultra_fast_charging_icon_dark,
  fast_charging_icon_dark,
  regular_charging_icon_dark,
} from '../assets/images';
import { CLEARANCE_DEFAULTS, FILTER_TYPE_CONTENT_KEY } from '@common/enums';
import { FilterOption } from '@common/interfaces';

export const DefaultSearchFilters: FilterOption[] = [
  {
    filterImage: ultra_fast_charging_icon,
    isSelected: true,
    type: FILTER_TYPE_CONTENT_KEY.ULTRA_FAST_CHARGER,
    value: 'Ultra Fast',
    category: 'chargerSpeed',
    darkFilterImage: ultra_fast_charging_icon_dark,
  },
  {
    filterImage: fast_charging_icon,
    isSelected: true,
    type: FILTER_TYPE_CONTENT_KEY.FAST_CHARGER,
    value: 'Fast',
    category: 'chargerSpeed',
    darkFilterImage: fast_charging_icon_dark,
  },
  {
    filterImage: regular_charging_icon,
    isSelected: false,
    type: FILTER_TYPE_CONTENT_KEY.REGULAR_CHARGER,
    value: 'Regular',
    category: 'chargerSpeed',
    darkFilterImage: regular_charging_icon_dark,
  },
  {
    filterImage: available_network,
    isSelected: false,
    type: FILTER_TYPE_CONTENT_KEY.AVAILABLE_CHARGER,
    value: 0,
  },
  {
    isSelected: false,
    type: FILTER_TYPE_CONTENT_KEY.HIDE_STATIONS_ADAPTER_REQUIRED,
    value: 0,
    description: 'nacs_adapter_text',
  },
  {
    filterTagText: 'in_network',
    isSelected: true,
    type: FILTER_TYPE_CONTENT_KEY.FORD_NETWORK,
    value: 0,
  },
  {
    filterTagText: 'p&c',
    isSelected: false,
    type: FILTER_TYPE_CONTENT_KEY.PLUG_N_CHARGE,
    value: 0,
  },
  {
    isSelected: false,
    type: FILTER_TYPE_CONTENT_KEY.CLEARANCE,
    value: CLEARANCE_DEFAULTS.METER_MAX,
  },
  {
    isSelected: false,
    type: FILTER_TYPE_CONTENT_KEY.TRAILER_ACCESS,
    value: 0,
    group: 'trailerAccess',
  },
  {
    isSelected: false,
    type: FILTER_TYPE_CONTENT_KEY.ATTACHED_TRAILER,
    value: 0,
    group: 'trailerAccess',
  },
  {
    isSelected: false,
    type: FILTER_TYPE_CONTENT_KEY.DETACHED_TRAILER,
    value: 0,
    group: 'trailerAccess',
  },
  {
    isSelected: false,
    type: FILTER_TYPE_CONTENT_KEY.FREE_CHARGE,
    value: 0,
  },
  {
    isSelected: false,
    type: FILTER_TYPE_CONTENT_KEY.MIN_RATING,
    value: 0,
  },
  {
    isSelected: false,
    type: FILTER_TYPE_CONTENT_KEY.RESTRICTED_CHARGERS,
    value: [-2, -1, 1],
  },
  {
    isSelected: false,
    type: FILTER_TYPE_CONTENT_KEY.NETWORK_ID,
    group: 'networks',
    value: [],
  },
];
