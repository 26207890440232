import {
  API_CACHE_TYPE,
  API_CONTENT_TYPE,
  API_CREDENTIALS,
  API_MODE,
  API_METHODS,
  NUMBER,
} from '@common/enums';
import {
  APICallOptions,
  FilterAPIOptions,
  GeoLocationObject,
  SearchLocationObject,
  SplunkServiceConfig,
  StationLocationObject,
} from '@common/interfaces';
import { LocalStorageUtils } from '@common/utilities';
import BaseService from './baseService';

export default class ChargerLocationService extends BaseService {
  /**
   * Gets Charger Locations Based on Geolocation or User Search Location
   * @param locationObj
   * @param reqPayload
   * @returns {Promise<any>}
   */
  public timeout = JSON.parse(
    process.env.REACT_APP_HMPC_NEAR_BY_CHARGERS_TIMEOUT_SETTING || '15000'
  );

  async getChargerLocationsAPI(
    locationObj: GeoLocationObject | SearchLocationObject,
    traceID: string | undefined,
    reqPayload: FilterAPIOptions = {},
    customHeaders: any = {}
  ): Promise<any> {
    const API_FIND_CHARGERS: string = process.env.REACT_APP_HMPC_API_FIND_CHARGERS || '';
    const latitude: number | null = this.getLatitude(locationObj);
    const longitude: number = this.getLongitude(locationObj);
    const languageCode: any = locationObj.languageCode;
    const filterRequest: any = { count: 20, ...reqPayload };
    const body: any = {
      latitude: latitude,
      longitude: longitude,
      languageCode: languageCode,
      radius: 80000,
      filter: filterRequest,
    };
    const options: APICallOptions = {
      method: API_METHODS.POST,
      mode: API_MODE.CORS,
      cache: API_CACHE_TYPE.NO_CACHE,
      credentials: API_CREDENTIALS.SAME_ORIGIN,
      headers: {
        Accept: API_CONTENT_TYPE.JSON,
        'Content-Type': API_CONTENT_TYPE.JSON,
        'x-b3-traceId': traceID,
        'x-b3-spanId': traceID,
        'x-correlation-id': traceID,
        url: API_FIND_CHARGERS,
        ...customHeaders,
      },
      body: JSON.stringify(body),
      timeout: this.timeout,
    };
    const splunkServiceConfig: SplunkServiceConfig = {
      service: 'ChargerLocationService',
      functionName: 'getChargerLocations',
      traceID,
      correlationID: traceID,
    };
    try {
      const { isSuccess, data, statusCode } = await this.fetchHandler(
        API_FIND_CHARGERS,
        options,
        splunkServiceConfig
      );
      return { isSuccess, data, statusCode };
    } catch (error) {
      const typedError = error as Error;
      this.splunkError(splunkServiceConfig, typedError?.message, '');
      return { isSuccess: false, data: null, statusCode: 500 };
    }
  }

  async getChargerLocations(
    locationObj: GeoLocationObject | SearchLocationObject,
    traceID: string | undefined,
    reqPayload: FilterAPIOptions = {},
    customHeaders: any = {}
  ): Promise<any> {
    const splunkServiceConfig: SplunkServiceConfig = {
      service: 'ChargerLocationService',
      functionName: 'getChargerLocations',
      traceID,
      correlationID: traceID,
    };
    try {
      const { isSuccess, data, statusCode } = await this.getChargerLocationsAPI(
        locationObj,
        traceID,
        reqPayload,
        customHeaders
      );
      if (isSuccess) {
        if (!!data && !!data.result && data.result.length >= 1) {
          LocalStorageUtils.setActiveSubscription(!!data?.activeSubscription);
          LocalStorageUtils.setAuthorizationType(data?.authorizationType);

          this.handleChargerLocationsResponse(data.result);
          return data;
        } else {
          this.splunkInfo(splunkServiceConfig, 'Received empty response for Charging locations.');
          return [];
        }
      }
      return statusCode === 500 ? false : { isSuccess, data, statusCode };
    } catch (error) {
      const typedError = error as Error;
      this.splunkError(splunkServiceConfig, typedError?.message, '');
      return false;
    }
  }

  handleChargerLocationsResponse(chargerLocationsData: StationLocationObject[]): void | boolean {
    LocalStorageUtils.setChargersNearby(chargerLocationsData);
  }

  /**
   * Gets latitude from geolocation/user search data or return default latitude
   * @param locationObj
   * @returns {number}
   */
  getLatitude(locationObj: GeoLocationObject | SearchLocationObject): number {
    if (!!locationObj && locationObj.latitude) return locationObj.latitude;

    return NUMBER.DEFAULT_LATITUDE;
  }

  /**
   * Gets longitude from geolocation/user search data or return default longitude
   * @param locationObj
   * @returns {number}
   */
  getLongitude(locationObj: GeoLocationObject | SearchLocationObject): number {
    if (!!locationObj && locationObj.longitude) return locationObj.longitude;

    return NUMBER.DEFAULT_LONGITUDE;
  }
}
