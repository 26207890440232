import React, { Component } from 'react';
import { qr_code_login_page } from '@common/assets/images';
import ChargerLocationService from '@common/services/chargerLocation.service';
import { v4 as uuidv4 } from 'uuid';
import GeoLocationService from '@common/services/geoLocation.service';
import { DefaultSearchFilters } from '@common/data/filters';
import { prepareRequestBody } from '../viewNearbyChargers/viewNearByChargersUtils';
import { NavigationContext } from '@common/contexts/navigationContext';
import { NavigationContextInterface } from '@common/interfaces';
import { LocalStorageUtils } from '@common/utilities';
import NavigationService from '@common/services/navigation.service';

interface RetailDecommissionProps {
  t: (arg0: string) => string;
}

interface RetailDecommissionState {
  hasActiveSubscription: boolean | null;
}

export default class RetailDecommission extends Component<
  RetailDecommissionProps,
  RetailDecommissionState
> {
  readonly navigationService: NavigationService;
  readonly chargerLocationService: ChargerLocationService;
  readonly geoLocationService: GeoLocationService;
  static contextType = NavigationContext;
  context!: React.ContextType<typeof NavigationContext>;

  constructor(props: RetailDecommissionProps, context: NavigationContextInterface) {
    super(props, context);
    this.navigationService = new NavigationService();
    this.chargerLocationService = new ChargerLocationService();
    this.geoLocationService = new GeoLocationService();
    this.state = {
      hasActiveSubscription: LocalStorageUtils.getActiveSubscription() || false,
    };
  }

  async setActiveSubscription() {
    const requestPosition = await this.geoLocationService.requestPosition();
    const location = requestPosition?.coords ?? null;
    location.languageCode = LocalStorageUtils.getLanguageCode() || 'en_US';
    if (location) {
      const traceID = uuidv4();
      const reqPayload = prepareRequestBody(DefaultSearchFilters, this.context.regionCode);
      this.chargerLocationService
        .getChargerLocationsAPI(location, traceID, reqPayload)
        .then(({ isSuccess, data }) => {
          if (isSuccess) {
            if (!!data && !!data.result && data.result.length >= 1) {
              this.setState({
                hasActiveSubscription: !!data?.activeSubscription,
              });
            }
          }
        });
    }
  }

  componentDidMount(): void {
    this.setActiveSubscription();
  }

  render() {
    const { t } = this.props;
    const { hasActiveSubscription } = this.state;
    const headerContent = 'retail_decommission_sub_header';
    const bodyContent = hasActiveSubscription
      ? 'retail_decommission_active_sub_body'
      : 'retail_decommission_not_active_sub_body';
    return (
      <div data-testid="decommission-wrapper" className="decommission-wrapper">
        <div data-testid="decommission-header" className="decommission-header">
          <div
            data-testid="decommission-header-content"
            className="decommission-header-content"
            dangerouslySetInnerHTML={{ __html: t(headerContent) }}
          />
          <div data-testid="find-chargers-container" className="find-chargers">
            <button
              type="button"
              className="button--secondary"
              data-testid="distraction-dismiss-btn"
              onClick={() => {
                this.navigationService.navigateToChargers();
              }}>
              {t('find_chargers')}
            </button>
          </div>
        </div>
        <div
          data-testid="decommission-body-content"
          className="decommission-body-content"
          dangerouslySetInnerHTML={{ __html: t(bodyContent) }}
        />
        <div className="retail-decommission-footer">
          <img
            data-testid="decommission-qr-code"
            className="qr-code"
            src={qr_code_login_page}
            alt="qr-code"
          />
        </div>
      </div>
    );
  }
}
