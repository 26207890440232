import React from 'react';
import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import App from './app';
import { I18nextProvider } from 'react-i18next';
import i18n from '@common/config/i18n';
import { unregister } from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import currentVersion from './versionData.json';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

const getNonce = () => {
  const script = document.querySelector('script[nonce]') as any;
  return script?.nonce;
};

const emotionCache = createCache({
  key: 'mui-style',
  nonce: getNonce(),
});

async function CheckVersionAndClearCache() {
  const clearCache = localStorage.getItem('cacheCleared')
    ? localStorage.getItem('cacheCleared')
    : 'NAN';

  const currentVersionValue = currentVersion.buildRevision;

  if (clearCache != currentVersionValue) {
    cacheClear(currentVersionValue);
  }
}

CheckVersionAndClearCache();

async function cacheClear(currentVersionValue) {
  try {
    if ('caches' in window && 'keys' in window.caches) {
      window.caches.keys().then((keys: any) => {
        return Promise.all(
          keys.map((k: string) => {
            caches.delete(k);
          })
        );
      });
      localStorage.setItem('cacheCleared', currentVersionValue);
      window.location.reload();
    }
  } catch (error) {
    console.log('error: ' + error);
  }
}

unregister();
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    registration.unregister();
  });
}

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <CacheProvider value={emotionCache}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </CacheProvider>
  );
}
reportWebVitals();
