// Some utility functions borrowed from
// https://github.ford.com/SYNC/menlo-core/tree/master/menlo-sdk/src/mqttApi

import ProtoBuf from 'protobufjs/light';

const topicTablesArray = {
  fileName: '',
};

const _topicTableMap = new Map();
const _topicDefPath = 'hmi-mqtt/dist/topic-tables';
const validTopicTypes = new Map([
  ['value', null],
  ['event', null],
  ['update', null],
]);

function indexOfTypeInTopicComponents(topicComponents: string[]): number {
  for (let i = 0; i < topicComponents.length; ++i) {
    if (validTopicTypes.has(topicComponents[i])) {
      return i;
    }
  }
  throw new Error(
    `${topicComponents.join(
      '/'
    )} does not follow convention {endpoint}/{domain}/{subdomain (optional)}/{type}/{function}`
  );
}
function getTopicTableFilename(topic: string): string {
  const topicComponents: string[] = topic.replace(/[<>]/g, '').split('/');
  const topicTypeIndex: number = indexOfTypeInTopicComponents(topicComponents);
  return topicComponents.slice(0, topicTypeIndex).join('_') + '.json';
}

function fetchJson(uri: any) {
  return require('hmi-mqtt/dist/topic-tables/' + topicTablesArray['fileName']);
}

function _fetchTopicTable(topic: string) {
  const filename = getTopicTableFilename(topic);
  let topicTable = _topicTableMap.get(filename);

  if (topicTable != null) {
    return topicTable;
  }
  topicTablesArray['fileName'] = `${filename}`;
  const uri = `${_topicDefPath}/${filename}`;
  const data = fetchJson(uri);
  if (typeof data === 'object') {
    topicTable = data;
    _topicTableMap.set(filename, topicTable);
    return topicTable;
  } else {
    throw new Error('Unexpected topic table data');
  }
}

function _fetchTopicDefinition(topic: any) {
  _fetchTopicTable(topic);
  const topicTable = _fetchTopicTable(topic);
  const topicDefinition = topicTable[topic];
  if (topicDefinition == null && topicDefinition !== undefined) {
    throw new Error(`Missing topic definition for ${topic}`);
  } else {
    return topicDefinition;
  }
}

function _getReflectionObjectForTopic(topic: any) {
  const topicDefinition = _fetchTopicDefinition(topic);
  const protoFile = require('hmi-mqtt/dist/proto/' + `${topicDefinition.protofile}`);
  try {
    const root = ProtoBuf.Root.fromJSON(protoFile);
    if (topicDefinition.message == null) {
      throw new Error('Undefined message in topic definition');
    }
    return root.lookupType(topicDefinition.message);
  } catch (error) {
    throw new Error(`Could not load type "${String(topicDefinition.message)}": ${error}`);
  }
}

/**
 * A custom protobuf decoder that avoids using eval() to prevent CSP errors.
 * This function uses direct byte parsing based on the message type definition.
 *
 * @param messageType The protobuf message type definition
 * @param bytes Uint8Array containing the binary protobuf message
 * @returns Decoded JavaScript object
 */
function safeProtobufDecode(messageType: any, bytes: Uint8Array) {
  try {
    // Create a reader for the binary data
    const reader = bytes && bytes.length ? new ProtoBuf.Reader(bytes) : null;
    if (!reader) {
      return `Invalid payload bytes: ${bytes}`;
    }
    // Create an empty object to store field values
    const result: any = {};

    // Read until the end of the buffer
    while (reader.pos < reader.len) {
      // Read the field tag (includes field number and wire type)
      const tag = reader.uint32();
      const fieldNumber = tag >>> 3;
      const wireType = tag & 0x07;
      // Get the field definition from the message type
      const field = messageType.fieldsById[fieldNumber];

      if (!field) {
        // Unknown field, skip based on wire type
        switch (wireType) {
          case 0:
            reader.skipType(0);
            break; // varint
          case 1:
            reader.skipType(1);
            break; // 64-bit
          case 2:
            reader.skipType(2);
            break; // bytes
          case 5:
            reader.skipType(5);
            break; // 32-bit
          default:
            return `Unknown wire type ${wireType} at position ${reader.pos}`;
        }
        continue;
      }

      // Read the field value based on its type
      let value;
      switch (field.type) {
        case 'double':
          value = reader.double();
          break;
        case 'float':
        case 'HMI_Float':
          value = reader.float();
          break;
        case 'int32':
        case 'HMI_Int':
          value = reader.int32();
          break;
        case 'uint32':
          value = reader.uint32();
          break;
        case 'sint32':
          value = reader.sint32();
          break;
        case 'fixed32':
          value = reader.fixed32();
          break;
        case 'sfixed32':
          value = reader.sfixed32();
          break;
        case 'int64':
          value = reader.int64();
          break;
        case 'uint64':
          value = reader.uint64();
          break;
        case 'sint64':
          value = reader.sint64();
          break;
        case 'fixed64':
          value = reader.fixed64();
          break;
        case 'sfixed64':
          value = reader.sfixed64();
          break;
        case 'bool':
        case 'HMI_Bool':
          value = reader.bool();
          break;
        case 'string':
        case 'HMI_String':
          value = reader.string();
          break;
        case 'bytes':
          value = reader.bytes();
          break;

        case 'enum':
        case 'ENUM_VehicleProgram':
        case 'ENUM_ChargeStatus':
        case 'ENUM_TimeFormat':
          // For enums, read the numeric value
          value = reader.int32();
          break;

        case 'message':
          // For nested messages, read the length and recursively decode
          value = safeProtobufDecode(field.resolvedType, reader.bytes());
          break;

        default:
          return `Unknown field type ${field.type}`;
      }

      // Handle repeated fields
      if (field.repeated) {
        if (!result[field.name]) {
          result[field.name] = [];
        }
        result[field.name].push(value);
      } else {
        result[field.name] = value;
      }
    }

    return result;
  } catch (error) {
    return `Protobuf decode error: ${error}`;
  }
}

export function decodePayloadFromMessage(message: any): any {
  try {
    const messageType = _getReflectionObjectForTopic(message.destinationName);

    // Return the full message instead of just the value property
    return safeProtobufDecode(messageType, new Uint8Array(message.payloadBytes));
  } catch (error) {
    return `Could not decode message: ${error}`;
  }
}
