import { AMPLITUDE_CONSTANTS, API_CONTENT_TYPE } from '@common/enums';
import { LocalStorageUtils, getTimeDifference } from '@common/utilities';
import { v4 as uuidv4 } from 'uuid';
import {
  SplunkServiceConfig,
  SplunkInfoLogger,
  chargerActivationData,
  ActivatedObjData,
  ActivationResponse,
  SplunkErrorLogger,
} from '@common/interfaces';
import BaseService from '../baseService';
import SplunkLogger from '../splunk.service';
import { AmplitudeObj } from '@common/interfaces/amplitudeData.interface';
import AmplitudeService from './../amplitude.service';
import { webSocketErrorHandler } from '@common/utilities/webSocketErrorHandler';
import ConfigDataService from '@common/services/getConfigData.service';
import io from 'socket.io-client';

export default class WebSocketService extends BaseService {
  private readonly errorText = 'Fetch request is successful and CallbackID is not available';
  public amplitudeService = new AmplitudeService();
  public configDataService = new ConfigDataService();
  public amplitudeObj!: AmplitudeObj;
  public resolveSubscribe!: boolean;
  public promiseResolved = false;

  /**
   * Activate charging session.
   * @param activateObject
   * @returns { Promise<any> }
   */
  public async activate(
    activateObject: ActivatedObjData,
    amplitudeObj: AmplitudeObj,
    chargingNetwork: string | undefined,
    traceID?: string
  ): Promise<any> {
    this.resolveSubscribe = false;
    const timeOutValue = await this.configDataService.getConfigData(traceID);
    const callbackId = uuidv4();
    const [subscribed, { isSuccess, data, statusCode }] = await Promise.all([
      this.subscribe(callbackId, traceID, timeOutValue.config_timeout),
      this.activateHandler(callbackId, activateObject, amplitudeObj, chargingNetwork, traceID),
    ]);
    const splunkServiceConfig: SplunkServiceConfig = {
      service: 'WebSocketService',
      functionName: 'activate',
      chargingNetwork: chargingNetwork,
      traceID,
      correlationID: traceID,
    };

    const clearTimeOutActivate = LocalStorageUtils.getTimeOutActivate() as any;
    clearTimeout(clearTimeOutActivate);
    LocalStorageUtils.clearTimeOutActivate();

    if (isSuccess) {
      LocalStorageUtils.setCallbackId(callbackId);
      return { callbackId: data.callbackId, status: subscribed.message.status };
    } else {
      let errorCode = 'default';
      if (statusCode && statusCode === 409) {
        errorCode = webSocketErrorHandler(data);
      }
      this.splunkInfo(splunkServiceConfig, errorCode);
      return { callbackId: null, error: errorCode };
    }
  }

  public async consumeMessage(
    socket: any,
    socketUrl: string,
    resolve: any,
    traceID: string | undefined,
    splunkServiceConfig: SplunkServiceConfig
  ): Promise<any> {
    //check if already resolved
    if (this.promiseResolved) {
      socket.disconnect();
      return;
    }
    socket.on(socketUrl, (msg, ack) => {
      ack(true);
      const res = this.messageArrived(msg, traceID);
      clearTimeout(LocalStorageUtils.getTimeOutActivate() as any);
      this.promiseResolved = true;
      resolve(res);
      socket.disconnect();
      SplunkLogger.complete({
        ...splunkServiceConfig,
        statusCode: '200',
        statusDesc: JSON.stringify(res).slice(0, 150),
        completeTime: new Date().getTime(),
      });
    });
  }

  public async activateHandler(
    callbackId: string,
    activateObject: ActivatedObjData,
    amplitudeObj: AmplitudeObj,
    chargingNetwork: string | undefined,
    traceID?: string
  ): Promise<any> {
    if (!traceID) {
      traceID = uuidv4();
    }
    this.amplitudeObj = { ...amplitudeObj };

    const API_ACTIVATE_URL: string | any = process.env.REACT_APP_HMPC_API_ACTIVATE_START;

    const body: chargerActivationData = {
      connectorId: activateObject.connectorId,
      evseAPIDataSource: activateObject.evseAPIDataSource,
      evseId: activateObject.evseId,
      streetLat: activateObject.streetLat,
      streetLon: activateObject.streetLon,
      locationId: activateObject.providerId,
      callbackId: callbackId,
    };

    const options: any = {
      method: 'POST',
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        Accept: API_CONTENT_TYPE.JSON,
        'Content-Type': API_CONTENT_TYPE.JSON,
        'x-b3-traceId': traceID,
        'x-b3-spanId': traceID,
        'x-correlation-id': traceID,
        url: API_ACTIVATE_URL,
      },
      body: JSON.stringify(body),
    };
    const splunkServiceConfig: SplunkServiceConfig = {
      service: 'WebSocketService',
      functionName: 'activate',
      chargingNetwork: chargingNetwork,
      traceID,
      correlationID: traceID,
    };
    let response: ActivationResponse = {
      isSuccess: false,
      data: {},
    };
    this.promiseResolved = false;
    try {
      response = await this.fetchHandler(API_ACTIVATE_URL, options, splunkServiceConfig);
    } finally {
      if (!response.isSuccess) {
        this.resolveSubscribe = true;
        this.promiseResolved = true;
      }
      const activateStatusCodeInfo: SplunkInfoLogger = {
        service: 'webSocket.service.tsx',
        functionName: 'activate',
        statusDesc: `activate response obj - response.statusCode: ${response.statusCode} && response.isSuccess: ${response.isSuccess} && response.data: ${response.data}`,
        logTime: new Date().getTime(),
        traceID: traceID,
        correlationID: traceID,
      };
      try {
        SplunkLogger.log(activateStatusCodeInfo);
        this.amplitudeObj.eventType = AMPLITUDE_CONSTANTS.ACTIVATE_BUTTON_RESPONSE;
        this.amplitudeObj.statusCode = this.getAmplitudeStatusCode(
          response.isSuccess,
          response.data,
          response.statusCode
        );
        await this.amplitudeService.postAmplitudeData(this.amplitudeObj);
      } catch (error) {
        activateStatusCodeInfo.statusDesc = `activate Splunk/Amplitude request statusCode error: ${error}`;
        this.splunkError(activateStatusCodeInfo, activateStatusCodeInfo.statusDesc, '');
      }
    }
    return response;
  }

  public messageArrived(response: any, traceID: string | undefined): any {
    try {
      //Below Splunk log is added for printing the message.
      const completeInfo: SplunkInfoLogger = {
        service: 'webSocket.service.tsx',
        functionName: 'messageArrived',
        statusDesc: `messageArrived value before parsing the response:  ${response} `,
        logTime: new Date().getTime(),
        traceID: traceID,
        correlationID: traceID,
      };
      SplunkLogger.log(completeInfo);
      const message = JSON.parse(response);
      this.amplitudeObj.eventType = AMPLITUDE_CONSTANTS.EVSS_CALLBACK_RECEIVED;
      this.amplitudeObj.status = message.status;
      this.amplitudeObj.callBackId = message.callbackId;
      this.amplitudeObj.duration = getTimeDifference(
        this.amplitudeObj.startTime,
        new Date().getTime()
      );
      this.amplitudeService.postAmplitudeData(this.amplitudeObj);
      if (message.status === 'ACCEPTED') {
        const completeInfoAfter: SplunkInfoLogger = {
          service: 'webSocket.service.tsx',
          functionName: 'messageArrived',
          statusDesc: `messageArrived value After parsing the JSON.parse(response.body):  ${message} `,
          logTime: new Date().getTime(),
          traceID: traceID,
          correlationID: traceID,
        };
        SplunkLogger.log(completeInfoAfter);
        LocalStorageUtils.setSessionId(message.sessionId);
        return { isSuccess: true, message: message };
      }
      return { isSuccess: false, message: message };
    } catch (error) {
      return { isSuccess: false, message: null };
    }
  }

  public TimedOut(timeOutValue: string, traceID: string | undefined): any {
    try {
      const completeInfo: SplunkInfoLogger = {
        service: 'webSocket.service.tsx',
        functionName: 'TimedOut',
        statusDesc: `The subscribe is not successful, Request Timeout after ${timeOutValue} milliseconds.`,
        logTime: new Date().getTime(),
        traceID: traceID,
        correlationID: traceID,
      };
      SplunkLogger.log(completeInfo);
      const message = {
        status: '408',
        callbackId: 'Did not receive callbackId, request timeout.',
      };

      this.amplitudeObj.eventType = AMPLITUDE_CONSTANTS.REQUEST_TIMEOUT;
      this.amplitudeObj.status = message.status;
      this.amplitudeObj.callBackId = message.callbackId;
      this.amplitudeObj.duration = getTimeDifference(
        this.amplitudeObj.startTime,
        new Date().getTime()
      );
      this.amplitudeService.postAmplitudeData(this.amplitudeObj);

      return { isSuccess: false, message: message };
    } catch (error) {
      return { isSuccess: false, message: null };
    }
  }

  public async subscribe(
    callbackId: string,
    traceID: string | undefined,
    timeOutValue: string
  ): Promise<any> {
    const subscribeURL = process.env.REACT_APP_HMPC_API_ACTIVATE_SUBSCRIBE as string;
    let setResolveSubscribe;
    const socketUrl = subscribeURL + callbackId;
    const socket = io({
      path: process.env.REACT_APP_HMPC_API_BASEPATH + '/socket.io',
      withCredentials: true,
      timeout: Number(timeOutValue),
      transports: ['polling', 'websocket'],
      extraHeaders: {
        mode: 'no-cors',
      },
      query: {
        callbackId: callbackId,
      },
    });
    const splunkServiceConfig: SplunkServiceConfig = {
      service: 'WebSocketService',
      functionName: 'StompClient-subscribe',
      traceID,
      correlationID: traceID,
    };
    SplunkLogger.start({ ...splunkServiceConfig, startTime: new Date().getTime() });
    return new Promise<boolean>((resolve) => {
      setResolveSubscribe = (value: boolean): any => {
        if (value) {
          socket.disconnect();
          resolve(false);
        }
      };
      this.getTimeOut(resolve, timeOutValue, socket, traceID);
      socket.on('connect', () => {
        this.consumeMessage(socket, socketUrl, resolve, traceID, splunkServiceConfig);
      });

      this.checkActivationStatus(5, callbackId, resolve, traceID, splunkServiceConfig);

      Object.defineProperty(this, 'resolveSubscribe', {
        set: setResolveSubscribe,
      });
    });
  }

  public async checkActivationStatus(
    intervalInSeconds: number,
    callbackId: string,
    resolve: any,
    traceID: string | undefined,
    splunkServiceConfig: SplunkServiceConfig
  ): Promise<any> {
    const splunkServiceConfigNew: SplunkServiceConfig = {
      ...splunkServiceConfig,
      functionName: 'checkActivationStatus',
    };
    const intervalId = setInterval(async () => {
      try {
        const API_ACTIVATION_STATUS: string | any = process.env.REACT_APP_HMPC_API_ACTIVATE_STATUS;
        const options: any = {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            Accept: API_CONTENT_TYPE.JSON,
            'Content-Type': API_CONTENT_TYPE.JSON,
            'x-b3-traceId': traceID,
            'x-b3-spanId': traceID,
            'x-correlation-id': traceID,
            url: API_ACTIVATION_STATUS,
          },
          body: JSON.stringify({ callbackId: callbackId }),
        };

        //check if already resolved
        if (this.promiseResolved) {
          clearInterval(intervalId);
          return;
        }

        const responseFromBackend = await this.fetchHandler(
          API_ACTIVATION_STATUS,
          options,
          splunkServiceConfigNew
        );
        if (responseFromBackend && responseFromBackend.statusCode === 200) {
          const message = responseFromBackend.data;
          if (message && message.status) {
            const response = this.messageArrived(JSON.stringify(message), traceID);
            this.promiseResolved = true;
            clearInterval(intervalId);
            resolve(response);
          }
        }
      } catch (error) {
        const completeInfo: SplunkErrorLogger = {
          service: 'webSocket.service.tsx',
          functionName: 'checkActivationStatus',
          error: 'Error while checking activation status',
          completeTime: new Date().getTime(),
          statusCode: '500',
          traceID: traceID,
          correlationID: traceID,
        };
        SplunkLogger.error(completeInfo);
      }
    }, intervalInSeconds * 1000);
  }

  public async getTimeOut(
    resolve: any,
    timeOutValue: string,
    socket: any,
    traceID: string | undefined
  ): Promise<any> {
    const timeOut = Number(timeOutValue);
    const clearTimeOutActivate = setTimeout(() => {
      const resTimeOut = this.TimedOut(timeOutValue, traceID);
      SplunkLogger.log({
        service: 'WebSocketService subscribe',
        functionName: 'setTimeout',
        statusDesc: `Timeout in milliseconds: ${timeOut} --Timeout Res:  ${JSON.stringify(
          resTimeOut
        )}`,
        logTime: Date.now(),
        traceID: traceID,
        correlationID: traceID,
      });
      socket.disconnect();
      resolve(resTimeOut);
    }, timeOut) as any;
    LocalStorageUtils.setTimeOutActivate(clearTimeOutActivate);
  }

  /**
   * Stop Activate charging session.
   * @param waitForResponse
   * @returns { Promise<any> }
   */

  public async deactivate(): Promise<any> {
    const traceID = uuidv4();
    const API_STOP_URL: string | any = process.env.REACT_APP_HMPC_API_ACTIVATE_STOP;
    const callBackID = LocalStorageUtils.getCallbackId();
    const body: any = {
      callbackId: callBackID,
    };
    const options: any = {
      method: 'POST',
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        Accept: API_CONTENT_TYPE.JSON,
        'Content-Type': API_CONTENT_TYPE.JSON,
        'x-b3-traceId': traceID,
        'x-b3-spanId': traceID,
        'x-correlation-id': traceID,
        url: API_STOP_URL,
      },
      body: JSON.stringify(body),
    };
    const splunkServiceConfig: SplunkServiceConfig = {
      service: 'WebSocketService',
      functionName: 'deactivate',
      traceID,
      correlationID: traceID,
    };
    LocalStorageUtils.resetActivationDetails();
    try {
      const { isSuccess, data, statusCode } = await this.fetchHandler(
        API_STOP_URL,
        options,
        splunkServiceConfig
      );
      const stopStatusCodeInfo: SplunkInfoLogger = {
        service: 'webSocket.service.tsx',
        functionName: 'deactivate',
        statusDesc: `verifying status code when we perform successful stop action:  ${statusCode} `,
        logTime: new Date().getTime(),
        traceID: traceID,
        correlationID: traceID,
      };
      SplunkLogger.log(stopStatusCodeInfo);
      this.amplitudeObj = {
        eventType: AMPLITUDE_CONSTANTS.STOP_VEHICLE_BUTTON_RESPONSE,
        callBackId: callBackID!,
        status: statusCode ? statusCode.toString() : '',
        traceID: traceID,
        screenName: 'WebSocketService',
      };
      this.amplitudeService.postAmplitudeData(this.amplitudeObj);
      if (isSuccess) {
        if (!!data && !!data.callbackId) {
          return true;
        } else {
          return false;
        }
      } else {
        this.splunkInfo(splunkServiceConfig, this.errorText);
        return false;
      }
    } catch (error) {
      const typedError = error as Error;
      this.splunkError(splunkServiceConfig, typedError?.message, '');
      this.amplitudeObj = {
        eventType: AMPLITUDE_CONSTANTS.STOP_VEHICLE_BUTTON_RESPONSE,
        status: typedError?.message,
        traceID: traceID,
        screenName: 'WebSocketService',
      };
      this.amplitudeService.postAmplitudeData(this.amplitudeObj);
      return false;
    }
  }
  getAmplitudeStatusCode(isSuccess, data, statusCode): string {
    if (isSuccess && data) {
      return '200';
    } else if (statusCode) {
      return statusCode.toString();
    } else {
      return '';
    }
  }
}
