import { LocalStorageUtils } from '@common/utilities';
import { t } from 'i18next';
import React, { Component, ReactElement, createRef } from 'react';
import './caRetailProgressBar.scss';

interface CAProgressBarProps {
  vehicleIsCharging: boolean | null | undefined;

  t(translationString: string): string;

  batteryChargedPercent: any;
  timeToCharge80PercentComplete?: string | null;
  timeToCharge100PercentComplete?: string | null;
  timeToCharge100PercentForAc?: string | null;
  evsePowerLevel: string;
}

export default class CARetailProgressBar extends Component<CAProgressBarProps> {
  private progressBarRef = createRef<HTMLDivElement>();

  componentDidMount() {
    this.updateProgressVariables();
  }

  componentDidUpdate() {
    this.updateProgressVariables();
  }

  private renderChargingEstimates(): ReactElement {
    const { vehicleIsCharging, evsePowerLevel } = this.props;
    return (
      <>
        {vehicleIsCharging && evsePowerLevel === 'Regular'
          ? this.renderEstimatesForRegularChargers()
          : null}
        {vehicleIsCharging && evsePowerLevel !== 'Regular'
          ? this.renderEstimatesForFastChargers()
          : null}
      </>
    );
  }

  private renderEstimatesForFastChargers(): ReactElement {
    const { batteryChargedPercent, timeToCharge80PercentComplete, timeToCharge100PercentComplete } =
      this.props;
    return (
      <>
        {batteryChargedPercent >= 80 ? null : (
          <div data-testid="percent-line-80" className="percentage-line percent-line-80"></div>
        )}
        {batteryChargedPercent == 100 ? null : (
          <div className="percentage-line percent-line-100" data-testid="percent-line-100"></div>
        )}
        {batteryChargedPercent >= 80 ? null : (
          <div
            className="percent-line-text percent-line-80-text"
            data-testid="percent-line-80-text">
            <span>{t('80%').toString()} </span>
            <span>
              +{timeToCharge80PercentComplete} {t('min').toString()}
            </span>
          </div>
        )}
        {batteryChargedPercent == 100 ? null : (
          <div
            className="percent-line-text percent-line-100-text"
            data-testid="percent-line-100-text">
            <span>{t('100%').toString()} </span>
            <span>
              +{timeToCharge100PercentComplete} {t('min').toString()}
            </span>
          </div>
        )}
      </>
    );
  }

  private renderEstimatesForRegularChargers(): ReactElement {
    const { batteryChargedPercent, timeToCharge100PercentForAc } = this.props;
    return (
      <>
        {batteryChargedPercent == 100 ? null : (
          <div className="percentage-line percent-line-100" data-testid="percent-line-100"></div>
        )}
        {batteryChargedPercent == 100 ? null : (
          <div
            className="percent-line-text percent-line-100-text"
            data-testid="percent-line-100-text">
            <span>{t('100%').toString()} </span>
            <span>
              +{Math.round(Number(timeToCharge100PercentForAc) * 60)} {t('min').toString()}
            </span>
          </div>
        )}
      </>
    );
  }

  // Update CSS variables when values change
  updateProgressVariables() {
    if (this.progressBarRef.current) {
      const startingPercentage = LocalStorageUtils.getStartingVehicleChargePercentage();
      const newlyAddedPercentage = this.props.batteryChargedPercent;

      this.progressBarRef.current.style.setProperty(
        '--starting-percentage',
        `${startingPercentage}%`
      );

      this.progressBarRef.current.style.setProperty(
        '--newly-added-percentage',
        `${newlyAddedPercentage}%`
      );
    }
  }

  render() {
    return (
      <>
        <div
          ref={this.progressBarRef}
          className="caretail-progressbar"
          data-testid="caretail-progressbar">
          <div className="container" data-testid="container">
            <div
              className="percentage-bar current-percentage"
              data-testid="current-percentage"></div>
            <div
              className="percentage-bar newly-added-percentage"
              data-testid="newly-added-percentage"></div>
          </div>
          {this.renderChargingEstimates()}
        </div>
      </>
    );
  }
}
