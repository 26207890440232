import React, { Component, ReactElement } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { NavigationContext } from '@common/contexts/navigationContext';
import {
  ActivatedObjData,
  ChargerData,
  ConnectorData,
  NavigationContextInterface,
  SplunkErrorLogger,
  SplunkInfoLogger,
  StationLocationObject,
} from '@common/interfaces';
import { LocalStorageUtils, getTimeDifference, getVehicleChargingTime } from '@common/utilities';
import Accordion from 'react-bootstrap/Accordion';
import { ChargingConnectorCard } from '../chargingConnectorCard/chargingConnectorCard';
import { InNetworkLink } from '../fragments/inNetworkLink/inNetworkLink';
import OutOfNetworkMsg from '../fragments/outOfNetworkMsg/outOfNetworkMsg';
import { AMPLITUDE_CONSTANTS, APP_COMPONENT } from '@common/enums';
import { CARetailLocationHeader } from '../fragments/locationHeader/caRetailLocationHeader';
import {
  ca_retail_accordion_dark,
  ca_retail_accordion_dark_open,
  ca_retail_accordion_light,
  ca_retail_accordion_light_open,
} from '@common/assets/images';
import WebSocketService from '@common/services/webSocketService/webSocket.service';
import AmplitudeService from '@common/services/amplitude.service';
import { v4 as uuidv4 } from 'uuid';
import { AmplitudeObj } from '@common/interfaces/amplitudeData.interface';
import { ActivatingStatusConfigs, ConnectorIdentifierConfigs } from '@common/data/connectorStatus';
import { RetailTimeOutModal } from '../modals/retailtimeoutmodalpopup/retailtimeoutModal';
import { DisclaimerModal } from '../modals/disclaimerModal/disclaimerModal';
import { ErrorModal } from '../modals/errormodalpopup/errorModal';
import { UnableToActiveErrorModal } from '../modals/unableToActiveErrorModal/unableToActiveErrorModal';
import SplunkLogger from '@common/services/splunk.service';
import CvPodService from '@common/services/cvPod.service';
import { renderSpinner } from '@common/utilities/determineSpinner';
import { VEHICLE_TYPE } from '@common/enums/vehicleType.enum';
import {
  getTimeoutStatus,
  getErrorCodes,
  getEVSSErrorCode,
  getEvsePowerLevel,
} from '@common/utilities/webSocketErrorHandler';
import { BOCNModal } from '../modals/bocnModal/bocnModal';
import isStationNotCompatible from '@common/utilities/isStationNotCompatible';
import getFlagValue from '@common/utilities/getFeatureFlagValue';

export interface ChargingLocationsProps extends RouteComponentProps {
  t: (arg0: string, arg1?: any) => string;
  passHistoryObj: (args0: any) => any;
  setConnectorInfo: (args0: any) => any;
  setPlugInTimeOut: (args: boolean) => void;
  preferredDistanceUnit: string;
  vehicleIsCharging: boolean | null;
  isChargeTargetReached: boolean;
  navigationAvailable: boolean | null;
  selectedChargerInfoObjData: StationLocationObject | null;
  setLessThan50: (arg: boolean) => void;
  lessThan50: boolean;
  vehiclePluggedIn: boolean;
  batteryChargedPercent: number;
  battery80PercentCharged: boolean;
  currentBatteryRange: string;
  timeToCharge80PercentComplete: string | null;
  timeToCharge100PercentComplete: string | null;
  timeToCharge100PercentForAc: any;
  vehicleType: VEHICLE_TYPE;
  plugInTimeOut: boolean;
  vehicleCharged: boolean;
  setVehicleCharged: () => void;
  isDriverRestrictionsActive: boolean;
  openDriverRestrictionsModal: () => void;
  isRetailDecommissionView: boolean;
}

export interface ChargingLocationsState {
  activeIndex: number | null;
  isDisclaimerModalOpenStatus: boolean;
  activatingStatusConfigs: Map<string, boolean>;
  connectorIdentifierConfigs: Map<string, string>;
  isTimeOutModalOpen: boolean;
  isErrorModalOpen: boolean;
  isStopFailedStatus: boolean;
  isUnableToActiveErrorModal: boolean;
  isLoading: boolean;
  isSubscriptionValid: boolean;
  showBOCNModal: boolean;
}

class ChargingLocations extends Component<ChargingLocationsProps, ChargingLocationsState> {
  chargingLocationObj: StationLocationObject | any;
  readonly WebSocketService: WebSocketService;
  readonly amplitudeService: AmplitudeService;
  readonly cvPodService: CvPodService;

  constructor(props: ChargingLocationsProps, context: NavigationContextInterface) {
    super(props, context);
    this.chargingLocationObj = this.props.selectedChargerInfoObjData
      ? this.props.selectedChargerInfoObjData
      : JSON.parse(LocalStorageUtils.getActiveChargerInfo());
    this.state = {
      activeIndex: null,
      isDisclaimerModalOpenStatus: false,
      activatingStatusConfigs: ActivatingStatusConfigs,
      connectorIdentifierConfigs: ConnectorIdentifierConfigs,
      isTimeOutModalOpen: false,
      isErrorModalOpen: false,
      isStopFailedStatus: false,
      isUnableToActiveErrorModal: false,
      isLoading: false,
      isSubscriptionValid: true,
      showBOCNModal: false,
    };
    this.WebSocketService = new WebSocketService();
    this.amplitudeService = new AmplitudeService();
    this.cvPodService = new CvPodService();
  }

  componentDidMount(): void {
    LocalStorageUtils.setCurrentPage(APP_COMPONENT.CHARGING_LOCATIONS);
    const canSeeBOCNModal = LocalStorageUtils.getCanSeeBOCNModal();
    this.passHistoryObj();
    const distance = this.props.selectedChargerInfoObjData?.distance ?? 51;
    const activeSubscription = LocalStorageUtils.getActiveSubscription();
    this.handleUnPlug();

    if (activeSubscription) {
      const subscriptionToggleValue = LocalStorageUtils.getSubscriptionToggleValue();
      if (subscriptionToggleValue === null) {
        this.getSubscription();
      } else {
        this.setState({ isSubscriptionValid: subscriptionToggleValue });
      }
    } else {
      this.setState({ isSubscriptionValid: false });
    }

    if (canSeeBOCNModal && distance <= 50 && activeSubscription === false) {
      this.setState({ showBOCNModal: true });
    }
  }

  componentDidUpdate(prevProps: ChargingLocationsProps) {
    if (prevProps !== this.props) {
      this.chargingLocationObj = this.props.selectedChargerInfoObjData
        ? this.props.selectedChargerInfoObjData
        : JSON.parse(LocalStorageUtils.getActiveChargerInfo());
      this.handleVehicleChargeSignal(prevProps);
      this.handleVehiclePlugInSignal(prevProps);
      this.handlePluginTimeout();
      this.handleStopCharging();
    }
  }

  getSubscription = (): void => {
    const traceID: string = uuidv4();
    const startTime = new Date().getTime();
    this.setState({ isLoading: true });
    this.cvPodService
      .getSubscription(traceID)
      .then((subscriptionResponse: any) => {
        LocalStorageUtils.setSubscriptionToggleValue(subscriptionResponse);
        this.setState({ isLoading: false, isSubscriptionValid: subscriptionResponse });
        this.amplitudeService.postAmplitudeData({
          eventType: AMPLITUDE_CONSTANTS.CVPOD_SUBSCRIPTION_VALUE,
          traceID: traceID,
          screenName: AMPLITUDE_CONSTANTS.CHARGING_LOCATIONS,
          isSubscriptionToggleOn: subscriptionResponse,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        const errorObj: SplunkErrorLogger = {
          service: 'CvpodService',
          functionName: 'getSubscription',
          traceID: traceID,
          completeTime: new Date().getTime(),
          error: error.message,
          statusCode: '',
          correlationID: traceID,
        };
        SplunkLogger.error(errorObj);
        this.amplitudeService.postAmplitudeData({
          eventType: AMPLITUDE_CONSTANTS.CVPOD_SUBSCRIPTION_REQUEST_FAILURE,
          traceID: traceID,
          screenName: AMPLITUDE_CONSTANTS.CHARGING_LOCATIONS,
          status: AMPLITUDE_CONSTANTS.CVPOD_SUBSCRIPTION_REQUEST_FAILURE,
          duration: getTimeDifference(startTime, new Date().getTime()),
        });
      });
  };

  handleUnPlug(): void {
    if (!this.props.vehiclePluggedIn && this.props.vehicleCharged) {
      ActivatingStatusConfigs['plugIn'] = false;
      ActivatingStatusConfigs['isChargingDone'] = true;
      ActivatingStatusConfigs['activationStatus'] = false;
      LocalStorageUtils.setActivatingStatusConfigs(ActivatingStatusConfigs);
      this.props.setVehicleCharged();
    }
  }

  handleStopCharging(): void {
    if (this.props.vehicleCharged) {
      ActivatingStatusConfigs['activationStatus'] = false;
    }
  }

  handlePluginTimeout(): void {
    if (this.props.plugInTimeOut && ActivatingStatusConfigs['plugIn']) {
      ActivatingStatusConfigs['plugIn'] = false;
      ActivatingStatusConfigs['activationStatus'] = false;
      ConnectorIdentifierConfigs['evseId'] = '';
      ConnectorIdentifierConfigs['connectorId'] = '';
      ConnectorIdentifierConfigs['locationId'] = '';
      this.setState({
        activatingStatusConfigs: ActivatingStatusConfigs,
        connectorIdentifierConfigs: ConnectorIdentifierConfigs,
      });
      LocalStorageUtils.setActivatingStatusConfigs(ActivatingStatusConfigs);
      this.props.setPlugInTimeOut(false);
    }
  }

  handleVehicleChargeSignal(prevProps: ChargingLocationsProps): void {
    if (!prevProps.vehicleIsCharging && !!this.props.vehicleIsCharging) {
      const range: string = LocalStorageUtils.getStartingVehicleRange();
      const amplitudeObj: AmplitudeObj = {
        eventType: AMPLITUDE_CONSTANTS.CHARGING_SESSION_BEGIN,
        screenName: AMPLITUDE_CONSTANTS.CHARGING_LOCATIONS,
        startRange: Number(range).toFixed(3).toString() + ' km',
        startSOC: LocalStorageUtils.getStartingVehicleChargePercentage(),
      };
      this.amplitudeService.postAmplitudeData(amplitudeObj);
      ActivatingStatusConfigs['plugIn'] = false;
      this.setState({
        activatingStatusConfigs: ActivatingStatusConfigs,
      });
      LocalStorageUtils.setActivatingStatusConfigs(ActivatingStatusConfigs);
    }

    if (prevProps.vehicleIsCharging && !this.props.vehicleIsCharging) {
      ActivatingStatusConfigs['plugIn'] = false;
      ActivatingStatusConfigs['isChargingDone'] = true;
      this.setState({
        activatingStatusConfigs: ActivatingStatusConfigs,
        connectorIdentifierConfigs: ConnectorIdentifierConfigs,
      });
      LocalStorageUtils.setActivatingStatusConfigs(ActivatingStatusConfigs);
    }
  }

  handleVehiclePlugInSignal(prevProps: ChargingLocationsProps): void {
    if (prevProps.vehiclePluggedIn && !this.props.vehiclePluggedIn) {
      ActivatingStatusConfigs['plugIn'] = false;
      ActivatingStatusConfigs['isChargingDone'] = true;
      ActivatingStatusConfigs['activationStatus'] = false;

      const vehicleStartRange = Number(LocalStorageUtils.getStartingVehicleRange());
      const endRange = Number(this.props.currentBatteryRange);
      const startSOC: number = LocalStorageUtils.getStartingVehicleChargePercentage();
      const endSOC: number = this.props.batteryChargedPercent;
      const chargeStartTime = LocalStorageUtils.getChargeStartTime();
      const timeOnCharger = getVehicleChargingTime(chargeStartTime, new Date().getTime());

      this.amplitudeService.postAmplitudeData({
        eventType: AMPLITUDE_CONSTANTS.CHARGE_SESSION_COMPLETE,
        screenName: AMPLITUDE_CONSTANTS.CHARGING_LOCATIONS,
        startRange: `${vehicleStartRange.toFixed(3)} km`,
        endRange: `${endRange.toFixed(3)} km`,
        distanceAdded: `${Number((endRange - vehicleStartRange).toFixed(3))} km`,
        startSOC,
        endSOC,
        timeOnCharger: `${timeOnCharger} mins`,
      });

      this.setState({
        activatingStatusConfigs: ActivatingStatusConfigs,
      });
      LocalStorageUtils.setActivatingStatusConfigs(ActivatingStatusConfigs);
      LocalStorageUtils.clearCallbackId();
    }
    if (!prevProps.vehiclePluggedIn && this.props.vehiclePluggedIn) {
      ActivatingStatusConfigs['plugIn'] = false;
      this.setState({
        activatingStatusConfigs: ActivatingStatusConfigs,
      });
      LocalStorageUtils.setActivatingStatusConfigs(ActivatingStatusConfigs);
    }
    if (this.props.isChargeTargetReached && this.props.vehiclePluggedIn) {
      ActivatingStatusConfigs['plugIn'] = false;
      ActivatingStatusConfigs['isChargingDone'] = true;
      this.setState({
        activatingStatusConfigs: ActivatingStatusConfigs,
      });
      LocalStorageUtils.setActivatingStatusConfigs(ActivatingStatusConfigs);
    }
  }

  chargingLocationDataExists(): boolean {
    return (
      this.chargingLocationObj !== undefined &&
      this.chargingLocationObj &&
      this.chargingLocationObj.chargers !== undefined &&
      this.chargingLocationObj.chargers.length >= 1
    );
  }

  isAccordionOpen(index: number): boolean {
    return this.state.activeIndex === index;
  }

  toggleAccordion(index: number): void {
    this.setState({
      activeIndex: this.isAccordionOpen(index) ? null : index,
    });
  }

  passHistoryObj = (): void => {
    this.props.passHistoryObj(this.props.history);
  };

  chargerClickHandler = (connectorObj: ConnectorData, connectorId: number | null) => {
    const activatedObjData: ActivatedObjData = {
      evseAPIDataSource: connectorObj.evseAPIDataSource,
      connector: connectorObj.connector,
      connectorId: connectorObj.connectorId,
      connectorPrice: connectorObj.connectorPrice,
      evseId: connectorObj.evseId,
      locationId: this.chargingLocationObj.locationId,
      streetLat: this.chargingLocationObj.streetLat,
      streetLon: this.chargingLocationObj.streetLon,
      providerId: connectorObj.providerId,
    };
    this.chargerClickHandlerActions(ActivatingStatusConfigs, activatedObjData);
  };

  chargerClickHandlerActions = (
    ActivatingStatusConfigs: Map<string, string>,
    activatedObjData: ActivatedObjData
  ): void => {
    const activatingStatusConfigs = this.props.vehicleIsCharging
      ? JSON.parse(LocalStorageUtils.getActivatingStatusConfigs()) || {}
      : ActivatingStatusConfigs;
    if (
      !activatingStatusConfigs.activationStatus &&
      !activatingStatusConfigs.plugIn &&
      !this.props.vehicleIsCharging
    ) {
      this.activateCharger(activatedObjData);
    }
  };

  chargerActivatedActions = (connectorObj: ActivatedObjData) => {
    LocalStorageUtils.resetChargingDetails();
    if (this.props.selectedChargerInfoObjData != null) {
      LocalStorageUtils.setCurrentlyChargingLocationID(connectorObj.locationId);
      LocalStorageUtils.setActivatedConnectorInfo(connectorObj);
      LocalStorageUtils.setActiveChargerInfo(this.props.selectedChargerInfoObjData);
    }
  };

  scrollTop = () => {
    (document.getElementById('ca-retail-header') as HTMLElement).scrollIntoView();
  };

  activateCharger = async (connectorObj: ActivatedObjData) => {
    const traceID: string = uuidv4();
    const startTime: number = new Date().getTime();
    const amplitudeObj: AmplitudeObj = {
      eventType: AMPLITUDE_CONSTANTS.ACTIVATE_BUTTON_TAPPED,
      locationId: connectorObj.locationId,
      evseId: connectorObj.evseId!,
      chargerNetworkName: this.chargingLocationObj.chargingNetwork,
      evsePowerLevel: getEvsePowerLevel(connectorObj),
      isChargerPnCCapable: this.chargingLocationObj.plugNCharge,
      isPluggedIn: this.props.vehiclePluggedIn,
      screenName: AMPLITUDE_CONSTANTS.CHARGING_LOCATIONS,
      traceID: traceID,
      country: this.chargingLocationObj.country,
      city: this.chargingLocationObj.city,
      region: this.chargingLocationObj.regionCode,
      startTime: startTime,
    };
    this.amplitudeService.postAmplitudeData(amplitudeObj);
    LocalStorageUtils.clearConnectorIdentifierConfigs();
    LocalStorageUtils.clearActivatingStatusConfigs();
    LocalStorageUtils.clearSessionId();
    try {
      ActivatingStatusConfigs['activating'] = true;
      ActivatingStatusConfigs['isChargingDone'] = false;
      ConnectorIdentifierConfigs['evseId'] = connectorObj.evseId;
      ConnectorIdentifierConfigs['connectorId'] = connectorObj.connectorId;
      ConnectorIdentifierConfigs['locationId'] = connectorObj.locationId;

      this.setState({
        activatingStatusConfigs: ActivatingStatusConfigs,
        connectorIdentifierConfigs: ConnectorIdentifierConfigs,
      });
      LocalStorageUtils.setConnectorIdentifierConfigs(ConnectorIdentifierConfigs);
      LocalStorageUtils.setActivatingStatusConfigs(ActivatingStatusConfigs);
      const { callbackId, error, status } = await this.WebSocketService.activate(
        connectorObj,
        amplitudeObj,
        this.chargingLocationObj.chargingNetwork,
        traceID
      );

      LocalStorageUtils.setStartingVehicleChargePercentage(this.props.batteryChargedPercent);
      LocalStorageUtils.setStartingVehicleRange(this.props.currentBatteryRange);
      if (callbackId && status === 'ACCEPTED') {
        const amplitudeObj: AmplitudeObj = {
          eventType: AMPLITUDE_CONSTANTS.SUCCESSFUL_ACTIVATION,
          screenName: AMPLITUDE_CONSTANTS.CHARGING_LOCATIONS,
          callBackId: callbackId,
          isPluggedIn: this.props.vehiclePluggedIn,
          chargerNetworkName: this.chargingLocationObj.chargingNetwork,
          traceID: traceID,
          evseId: connectorObj.evseId!,
          locationId: connectorObj.locationId,
          evsePowerLevel: getEvsePowerLevel(connectorObj),
          duration: getTimeDifference(startTime, new Date().getTime()),
          statusCode: '200',
        };

        this.amplitudeService.postAmplitudeData(amplitudeObj);

        ActivatingStatusConfigs['activating'] = false;
        ActivatingStatusConfigs['activationStatus'] = true;
        if (!this.props.vehiclePluggedIn) {
          ActivatingStatusConfigs['plugIn'] = true;
          this.props.setPlugInTimeOut(true);
        }
        this.setState({
          activatingStatusConfigs: ActivatingStatusConfigs,
          connectorIdentifierConfigs: ConnectorIdentifierConfigs,
        });
        LocalStorageUtils.setConnectorIdentifierConfigs(ConnectorIdentifierConfigs);
        LocalStorageUtils.setActivatingStatusConfigs(ActivatingStatusConfigs);
        this.chargerActivatedActions(connectorObj);
        this.scrollTop();
      } else if (getTimeoutStatus(status)) {
        ActivatingStatusConfigs['activating'] = false;
        ConnectorIdentifierConfigs['evseId'] = '';
        ConnectorIdentifierConfigs['connectorId'] = '';
        ConnectorIdentifierConfigs['locationId'] = '';
        this.setState({
          activatingStatusConfigs: ActivatingStatusConfigs,
          isTimeOutModalOpen: true,
          connectorIdentifierConfigs: ConnectorIdentifierConfigs,
        });
        LocalStorageUtils.setActivatingStatusConfigs(ActivatingStatusConfigs);
        LocalStorageUtils.clearCallbackId();
      } else if (getErrorCodes(error)) {
        ActivatingStatusConfigs['activating'] = false;
        ActivatingStatusConfigs['plugIn'] = false;
        ConnectorIdentifierConfigs['evseId'] = '';
        ConnectorIdentifierConfigs['connectorId'] = '';
        ConnectorIdentifierConfigs['locationId'] = '';
        LocalStorageUtils.clearCallbackId();
        this.setState({
          isUnableToActiveErrorModal: true,
          activatingStatusConfigs: ActivatingStatusConfigs,
          connectorIdentifierConfigs: ConnectorIdentifierConfigs,
        });
      } else if (getEVSSErrorCode(error)) {
        ActivatingStatusConfigs['activating'] = false;
        ActivatingStatusConfigs['plugIn'] = false;
        ConnectorIdentifierConfigs['evseId'] = '';
        ConnectorIdentifierConfigs['connectorId'] = '';
        ConnectorIdentifierConfigs['locationId'] = '';
        LocalStorageUtils.clearCallbackId();
        this.setState({
          isTimeOutModalOpen: true,
          activatingStatusConfigs: ActivatingStatusConfigs,
          connectorIdentifierConfigs: ConnectorIdentifierConfigs,
        });
      } else {
        ActivatingStatusConfigs['activating'] = false;
        ConnectorIdentifierConfigs['evseId'] = '';
        ConnectorIdentifierConfigs['connectorId'] = '';
        ConnectorIdentifierConfigs['locationId'] = '';
        this.setState({
          activatingStatusConfigs: ActivatingStatusConfigs,
          connectorIdentifierConfigs: ConnectorIdentifierConfigs,
          isErrorModalOpen: true,
        });
        LocalStorageUtils.setActivatingStatusConfigs(ActivatingStatusConfigs);
        LocalStorageUtils.clearCallbackId();
      }
    } catch (error) {
      ActivatingStatusConfigs['activating'] = false;
      ConnectorIdentifierConfigs['evseId'] = '';
      ConnectorIdentifierConfigs['connectorId'] = '';
      ConnectorIdentifierConfigs['locationId'] = '';
      this.setState({
        activatingStatusConfigs: ActivatingStatusConfigs,
        connectorIdentifierConfigs: ConnectorIdentifierConfigs,
      });
      LocalStorageUtils.setActivatingStatusConfigs(ActivatingStatusConfigs);
      LocalStorageUtils.clearCallbackId();
    }
  };

  private renderAccordionToggleRetail(index: number, key: any): ReactElement {
    return (
      <div data-id="accordion-toggle" key={key}>
        <div className="d-flex align-items-center">
          {this.isAccordionOpen(index) ? (
            <>
              <img
                alt="Accordion Open"
                className="light-icon"
                src={ca_retail_accordion_light_open}
              />
              <img alt="Accordion Open" className="dark-icon" src={ca_retail_accordion_dark_open} />{' '}
            </>
          ) : (
            <>
              <img alt="Accordion Close" className="light-icon" src={ca_retail_accordion_light} />
              <img alt="Accordion Close" className="dark-icon" src={ca_retail_accordion_dark} />
            </>
          )}
          <span className="charger-stalls-label">{this.props.t('charger_stalls')}</span>
        </div>
      </div>
    );
  }

  renderNetworkStatus = (connectorsArray: any, charger: ChargerData, t: any): ReactElement => {
    return (
      <>
        {this.chargingLocationObj.networksAccepted ? (
          <InNetworkLink
            {...connectorsArray}
            t={t}
            connectorsData={connectorsArray}
            connectorId={charger.connector}
            connectorEvseID={!!connectorsArray.evseId ? connectorsArray.evseId : null}
            onClickCallback={this.chargerClickHandler}
            vehicleIsCharging={this.props.vehicleIsCharging}
            vehicleIsPluggedIn={this.props.vehiclePluggedIn}
            ActivatingStatusConfigs={this.state.activatingStatusConfigs}
            ConnectorIdentifierConfigs={this.state.connectorIdentifierConfigs}
            isSubscriptionValid={this.state.isSubscriptionValid}
            openBOCNModal={this.openBOCNModal}
            isDriverRestrictionsActive={this.props.isDriverRestrictionsActive}
            openDriverRestrictionsModal={this.props.openDriverRestrictionsModal}
          />
        ) : (
          <OutOfNetworkMsg />
        )}
      </>
    );
  };

  shouldShowProgressBar = (connectorsArray): boolean => {
    const connectorIdentifierConfigs =
      JSON.parse(LocalStorageUtils.getConnectorIdentifierConfigs()) || {};
    const activatingStatusConfigs =
      JSON.parse(LocalStorageUtils.getActivatingStatusConfigs()) || {};
    if (
      connectorsArray?.evseId === connectorIdentifierConfigs.evseId &&
      connectorsArray?.connectorId === connectorIdentifierConfigs.connectorId &&
      (activatingStatusConfigs.isChargingDone || this.props.vehicleIsCharging)
    ) {
      return true;
    }
    return false;
  };

  shouldSortConnectorsList = (connectorsArray): boolean => {
    const connectorIdentifierConfigs = this.props.vehicleIsCharging
      ? JSON.parse(LocalStorageUtils.getConnectorIdentifierConfigs()) || {}
      : this.state.connectorIdentifierConfigs;
    const activatingStatusConfigs = this.props.vehicleIsCharging
      ? JSON.parse(LocalStorageUtils.getActivatingStatusConfigs()) || {}
      : this.state.activatingStatusConfigs;
    if (
      connectorIdentifierConfigs.evseId === connectorsArray.evseId &&
      connectorIdentifierConfigs.connectorId === connectorsArray.connectorId &&
      activatingStatusConfigs.activationStatus
    ) {
      return true;
    }
    return false;
  };

  prepareChargingStalls = (charger: ChargerData, t, regionCode) => {
    const { connectorIdentifierConfigs } = this.state;
    const orderedConnectors: any = [];
    charger.connectors.forEach((connectorsArray: any) => {
      if (this.shouldSortConnectorsList(connectorsArray)) {
        orderedConnectors.unshift(connectorsArray);
      } else {
        orderedConnectors.push(connectorsArray);
      }
    });

    return (
      <>
        {orderedConnectors.map((connectorsArray: any, index: number) => {
          const networksStatusText = this.renderNetworkStatus(connectorsArray, charger, t);
          return (
            <div key={index}>
              <ChargingConnectorCard
                t={t}
                {...connectorsArray}
                connectorStatus={connectorsArray.connectorStatus}
                connectorId={connectorsArray.connectorId}
                connectorSpeed={connectorsArray.connectorSpd}
                connectorType={connectorsArray.connector}
                evseExtID={connectorsArray.evseExtidentifier}
                connectorPrice={connectorsArray.connectorPrice}
                netWorksAccepted={this.chargingLocationObj.networksAccepted}
                networkStatus={networksStatusText}
                lessThan50={this.props.lessThan50}
                regionCode={regionCode}
                batteryChargedPercent={this.props.batteryChargedPercent}
                vehicleIsCharging={this.props.vehicleIsCharging}
                showProgressbar={this.shouldShowProgressBar(connectorsArray)}
                preferredDistanceUnit={this.props.preferredDistanceUnit}
                battery80PercentCharged={this.props.battery80PercentCharged}
                currentBatteryRange={this.props.currentBatteryRange}
                timeToCharge80PercentComplete={this.props.timeToCharge80PercentComplete}
                timeToCharge100PercentComplete={this.props.timeToCharge100PercentComplete}
                evseId={connectorsArray.evseId}
                timeToCharge100PercentForAc={this.props.timeToCharge100PercentForAc}
                connectorIdentifierConfigs={connectorIdentifierConfigs}
                locationId={connectorsArray.locationId}
                nacsAdapterRequired={this.chargingLocationObj.nacsAdapterRequired}
              />
            </div>
          );
        })}
      </>
    );
  };

  renderCAChargers(t: any, index: number, charger: ChargerData, regionCode: string): ReactElement {
    const isShowAccordion = this.props.lessThan50 === true;
    const accordionClassName: string = this.isAccordionOpen(index)
      ? `accordion--open ${index === 0 ? 'firstAccordion--open' : null}`
      : 'accordion--closed';
    if (!isShowAccordion) {
      return (
        <div data-testid="chargers" className={accordionClassName}>
          <Accordion
            className="accordion-toggle"
            onClick={() => this.toggleAccordion(index)}
            activeKey={index.toString()}>
            <div data-testid="card-header" className={accordionClassName}>
              <div className="page__card-btn">
                {this.renderAccordionToggleRetail(index, charger.connector)}
              </div>
            </div>
          </Accordion>
          {/***** End of Accordion Header *****/}

          <Accordion.Collapse eventKey={index.toString()}>
            <>{this.prepareChargingStalls(charger, t, regionCode)}</>
          </Accordion.Collapse>
        </div>
      );
    }

    return <>{this.prepareChargingStalls(charger, t, regionCode)}</>;
  }

  closeTimeOutModal = (): void => {
    this.setState({ isTimeOutModalOpen: false });
  };

  closeErrorModal = (): void => {
    this.setState({ isErrorModalOpen: false });
  };

  closeUnableToActiveErrorModal = (): void => {
    this.setState({ isUnableToActiveErrorModal: false });
  };

  showBOCNMobalPopup = (showBOCNModal: boolean): boolean => {
    return !this.props.vehicleIsCharging && showBOCNModal;
  };

  renderHeader() {
    const { t } = this.props;
    const chargingLocationObj = this.chargingLocationObj;
    const isLocationExists = this.chargingLocationDataExists();
    if (!isLocationExists) {
      return null;
    }
    return (
      <CARetailLocationHeader
        lessThan50={this.props.lessThan50}
        setLessThan50={this.props.setLessThan50}
        history={this.props.history}
        t={t}
        ChargingLocationsObj={chargingLocationObj}
        preferredDistanceUnit={this.props.preferredDistanceUnit}
        navigationAvailable={this.props.navigationAvailable}
        isDriverRestrictionsActive={this.props.isDriverRestrictionsActive}
        openDriverRestrictionsModal={this.props.openDriverRestrictionsModal}
      />
    );
  }

  renderStalls() {
    const { t } = this.props;
    const chargingLocationObj = this.chargingLocationObj;
    return (
      <Accordion activeKey={this.state.activeIndex?.toString()}>
        {chargingLocationObj.chargers.map((charger: ChargerData, index: number) => {
          charger.connectors.forEach(
            (connector: any) => (connector.locationId = chargingLocationObj.locationId)
          );
          return (
            <div key={index}>
              {charger.connectors.length > 0 &&
                this.renderCAChargers(t, index, charger, chargingLocationObj.regionCode)}
            </div>
          );
        })}
      </Accordion>
    );
  }

  closeBOCNModal = (): void => {
    this.setState({ showBOCNModal: false });
    LocalStorageUtils.setCanSeeBOCNModal(false);
  };

  openBOCNModal = () => {
    this.setState({ showBOCNModal: true });
  };
  private handleDisclaimer = (): void => {
    const amplitudeObj = {
      eventType: AMPLITUDE_CONSTANTS.DISCLAIMER_BUTTON_TAPPED,
      screenName: AMPLITUDE_CONSTANTS.CHARGING_LOCATIONS,
    };
    this.amplitudeService.postAmplitudeData(amplitudeObj);
    this.setState({ isDisclaimerModalOpenStatus: true });
  };

  render(): React.ReactNode {
    const { t, vehicleType } = this.props;
    const {
      isDisclaimerModalOpenStatus,
      isTimeOutModalOpen,
      isErrorModalOpen,
      isUnableToActiveErrorModal,
      isStopFailedStatus,
      isLoading,
    } = this.state;
    const regionCode =
      this.chargingLocationObj && this.chargingLocationObj.regionCode
        ? this.chargingLocationObj.regionCode
        : 'NA';
    const showAdapterDiv = getFlagValue('tesla-changes');
    if (this.props.isRetailDecommissionView) {
      return <Redirect to="/hmpcRetailDecommission" />;
    }
    return (
      <>
        {isLoading ? (
          renderSpinner(vehicleType)
        ) : (
          <div
            className="container-fluid charging-locations-container"
            data-testid="ChargingLocations">
            {this.showBOCNMobalPopup(this.state.showBOCNModal) ? (
              <div data-testid="bocn-modal">
                <BOCNModal t={t} closeModal={this.closeBOCNModal} regionCode={regionCode} />
              </div>
            ) : null}
            {isTimeOutModalOpen && !this.props.vehicleIsCharging ? (
              <RetailTimeOutModal
                t={t}
                isOpen={isTimeOutModalOpen}
                closeModal={this.closeTimeOutModal}
              />
            ) : null}
            {isErrorModalOpen ? (
              <ErrorModal
                t={t}
                isOpen={isErrorModalOpen}
                closeModal={this.closeErrorModal}
                errorModalDesc={'default'}
              />
            ) : null}
            {isUnableToActiveErrorModal ? (
              <UnableToActiveErrorModal
                t={t}
                isOpen={isUnableToActiveErrorModal}
                closeModal={this.closeUnableToActiveErrorModal}
              />
            ) : null}
            {
              <>
                {this.renderHeader()}
                {!isStationNotCompatible(this.chargingLocationObj, showAdapterDiv) && (
                  <div className="body" data-testid="body-chargers-stalls">
                    {this.renderStalls()}
                  </div>
                )}
                <div
                  className="disclaimer-text"
                  data-testid="disclaimer-text"
                  onClick={this.handleDisclaimer}>
                  {t('r_disclaimer')}
                </div>
              </>
            }
            {isDisclaimerModalOpenStatus && (
              <DisclaimerModal
                isOpen={isDisclaimerModalOpenStatus}
                t={t}
                closeModal={() => this.setState({ isDisclaimerModalOpenStatus: false })}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

ChargingLocations.contextType = NavigationContext;
export default withTranslation('translations')(ChargingLocations as any);
export { ChargingLocations };
