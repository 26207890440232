module.exports = function inquire(moduleName) {
  // Safe implementation that doesn't use eval
  const commonModules = {
    fs: null,
    buffer: require('buffer'), // Binary data support
    long: require('long'), // 64-bit integer support
    // Add other modules you need to handle
  };

  return commonModules[moduleName] || null;
};
