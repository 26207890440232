import { AMPLITUDE_CONSTANTS, FILTER_TYPE_CONTENT_KEY, NUMBER } from '@common/enums';
import {
  FilterOption,
  FilterAPIOptions,
  SuggestionValueObject,
  GeoLocationCoords,
  StationLocationObject,
  LatitudeLongitude,
} from '@common/interfaces';
import { LocalStorageUtils, isNARegion } from '@common/utilities';
import getFlagValue from '@common/utilities/getFeatureFlagValue';
import haversine from 'haversine-distance';

export const getTrailerAccessData = (filters: FilterOption[]): string[] | undefined => {
  const trailerAccess: string[] = [];
  filters.forEach((filter: FilterOption) => {
    switch (filter.type) {
      case FILTER_TYPE_CONTENT_KEY.ATTACHED_TRAILER:
        filter.isSelected && trailerAccess.push('Trailer Friendly');
        break;
      case FILTER_TYPE_CONTENT_KEY.DETACHED_TRAILER:
        filter.isSelected && trailerAccess.push('Trailer Parking');
        break;
      default:
        break;
    }
  });
  return trailerAccess.length > 0 ? trailerAccess : undefined;
};

const convertFalseToUndefined = (isSelected: boolean): boolean | undefined => {
  return isSelected ? true : undefined;
};

export const handleMinRatingFilter = (filter: FilterOption): number | undefined => {
  //This check is added because sending a score of 0 to LA is not allowed
  if (filter.value === 0) {
    return undefined;
  } else {
    return filter.isSelected ? filter.value : undefined;
  }
};

export const prepareRequestBody = (filters: FilterOption[], regionCode): FilterAPIOptions => {
  let reqPayload: FilterAPIOptions = {};
  const networkId: number[] = [];
  const evsePowerLevel: string[] = [];

  const handleRegularFastUltraCharger = (filter): any => {
    return filter.isSelected ? evsePowerLevel.push(filter.value) : [];
  };

  const handleAvailableCharger = (filter): any => {
    return { evseAvailability: convertFalseToUndefined(filter.isSelected) };
  };

  const handleFordNetwork = (filter): any => {
    return { networksAccepted: convertFalseToUndefined(filter.isSelected) };
  };

  const handlePlugNCharge = (filter): any => {
    return { plugNChargeOnly: convertFalseToUndefined(filter.isSelected) };
  };

  const handleFreeCharge = (filter): any => {
    return { freeChargeOnly: convertFalseToUndefined(filter.isSelected) };
  };
  const handleMinRating = (filter): any => {
    return { minimumScore: handleMinRatingFilter(filter) };
  };
  const handleClearance = (filter): any => {
    return filter.isSelected ? { overHeadClearance: filter.value } : {};
  };
  const handleRestrictedChargers = (filter): any => {
    return { accessTypeId: filter.isSelected ? [-2, -1, 1, 2] : [-2, -1, 1] };
  };
  const handleAttachedDetachedTrailer = (filter): any => {
    return { parkingObjectFilter: getTrailerAccessData(filters) };
  };

  const handleHideStationsAdapterFilter = (filter): any => {
    if (isNARegion(regionCode, getFlagValue('tesla-changes'))) {
      return { includeNACSAdapterRequiredLocations: !filter.isSelected };
    }
  };

  const handleDefault = (filter): any => {
    if (filter.group === 'networks' && filter.isSelected) {
      networkId.push(filter.value);
    }
  };

  const filterHandlers = {
    [FILTER_TYPE_CONTENT_KEY.REGULAR_CHARGER]: handleRegularFastUltraCharger,
    [FILTER_TYPE_CONTENT_KEY.FAST_CHARGER]: handleRegularFastUltraCharger,
    [FILTER_TYPE_CONTENT_KEY.ULTRA_FAST_CHARGER]: handleRegularFastUltraCharger,
    [FILTER_TYPE_CONTENT_KEY.AVAILABLE_CHARGER]: handleAvailableCharger,
    [FILTER_TYPE_CONTENT_KEY.FORD_NETWORK]: handleFordNetwork,
    [FILTER_TYPE_CONTENT_KEY.PLUG_N_CHARGE]: handlePlugNCharge,
    [FILTER_TYPE_CONTENT_KEY.FREE_CHARGE]: handleFreeCharge,
    [FILTER_TYPE_CONTENT_KEY.MIN_RATING]: handleMinRating,
    [FILTER_TYPE_CONTENT_KEY.CLEARANCE]: handleClearance,
    [FILTER_TYPE_CONTENT_KEY.RESTRICTED_CHARGERS]: handleRestrictedChargers,
    [FILTER_TYPE_CONTENT_KEY.HIDE_STATIONS_ADAPTER_REQUIRED]: handleHideStationsAdapterFilter,
    [FILTER_TYPE_CONTENT_KEY.ATTACHED_TRAILER]: handleAttachedDetachedTrailer,
    [FILTER_TYPE_CONTENT_KEY.DETACHED_TRAILER]: handleAttachedDetachedTrailer,
    default: handleDefault,
  };

  filters.forEach((filter: FilterOption) => {
    const handler = filterHandlers[filter.type] || filterHandlers.default;
    const handlerResult = handler(filter);
    reqPayload = { ...reqPayload, ...handlerResult };
  });
  reqPayload.networkId = networkId.length > 0 ? networkId : undefined;
  reqPayload.evsePowerLevel = evsePowerLevel.length > 0 ? evsePowerLevel : undefined;
  reqPayload.parkingObjectFilter = getTrailerAccessData(filters);
  return reqPayload;
};

export const getNetworkFilters = (
  chargers: any | null,
  filters: FilterOption[]
): FilterOption[] => {
  const chargerArray: any[] = !!chargers ? chargers : [];
  const networkFilters: FilterOption[] = [];
  const chargerNetworks = chargerArray.map((charger: any) => {
    return { type: charger.chargingNetwork, value: charger.chargingNetworkId };
  });
  chargerNetworks.forEach((item: any, index: number) => {
    const oldPos = networkFilters.findIndex((ele: FilterOption) => ele.type === item.type);
    const prevIsSelected: FilterOption | undefined = filters.find(
      (filter: FilterOption) => filter.type === item.type
    );
    if (
      (item && item.type !== 'Not Provided' && oldPos === -1) ||
      (item && item.type !== 'Not Applicable' && oldPos === -2)
    ) {
      networkFilters.push({
        type: item.type,
        isSelected: prevIsSelected ? prevIsSelected.isSelected : false,
        group: 'networks',
        value: item.value,
      });
    }
  });
  return networkFilters;
};

export const getScreenName = (isFirstLoad: boolean, locationState: unknown): string => {
  let screenName = '';
  if (isFirstLoad) {
    screenName = AMPLITUDE_CONSTANTS.NEARBY_CHARGERS;
  } else if (locationState) {
    const fromScreen = (locationState as string) ?? '';
    screenName = fromScreen.replace('/', '');
  }
  return screenName;
};

export const updateSavedSearches = (suggestion: SuggestionValueObject): void => {
  const savedSearches: SuggestionValueObject[] = LocalStorageUtils.getSavedSearches();
  const duplicateIndex: number = savedSearches.findIndex((entry) => {
    if (entry.title === suggestion.title) {
      if (entry.vicinity === suggestion.vicinity) {
        return true;
      }
    }
  });
  //If suggestion is already on savedSearches, move it to the top of the list
  if (duplicateIndex !== -1) {
    savedSearches.splice(duplicateIndex, 1);
  }
  //If there are already 10 saved searches, remove the oldest one
  else if (savedSearches.length == 10) {
    savedSearches.shift();
  }
  savedSearches.push(suggestion);
  LocalStorageUtils.setSavedSearches(savedSearches);
};

export const calculateChargerLocations = (
  newLocation: GeoLocationCoords,
  chargers: StationLocationObject[]
): StationLocationObject[] => {
  const newChargers = chargers.map((charger: any) => {
    const chargerLocation: LatitudeLongitude = {
      latitude: charger.streetLat,
      longitude: charger.streetLon,
    };
    const deviceLocation: LatitudeLongitude = {
      latitude: newLocation.coords.latitude ?? NUMBER.DEFAULT_LATITUDE,
      longitude: newLocation.coords.longitude ?? NUMBER.DEFAULT_LONGITUDE,
    };
    const newChargerDistance = haversine(chargerLocation, deviceLocation);
    return {
      ...charger,
      distance: newChargerDistance,
    };
  });
  return newChargers.sort((a, b) => a.distance - b.distance);
};

export const getErrorMessage = (errorObj: any): string => {
  return errorObj
    ? errorObj.message
      ? errorObj.message
      : 'Error message is null'
    : 'Error is null';
};
